@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
      overflow-x: hidden;
  
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
.Sidebar {
    height: 100%;
    /* border-radius: 20px; */
    width: 240px;
    background-image: linear-gradient(to right, #54006B, #CC123D);
    position: fixed;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    background-color: #f3f3f3;
}

.SidebarList {
    height: auto;
    padding: 0;
    width: 100%;
}

.SidebarList .row {
    width: 100%;
    height: 60px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: #fff;
    justify-content: center;
    align-items: center;
}

.row #icon {
    flex: 30% 1;
    display: grid;
    place-items: center;
}

.row #title {
    flex: 70% 1;
}

.SidebarList .row:hover {
    cursor: pointer;
    font-weight: 600;
}

.SidebarList #active {
    font-weight: 600;
}

.side {
    /* padding: 20px; */
}

.navbar {
    position: fixed;
}

.pad {
    padding: 50px;
    padding-top: 0px;
}

.head {
    /* margin-left: 30px; */
    color: linear-gradient(to right, #54006B, #CC123D);
}

/* Modal Project edit */
/* .modal-dialog{
    display: block !important;
}
.modal-dialog, .modal-content {
    height: 100%;
    width: 2000px
  }
  
  .modal-body {
    max-height: calc(100% - 120px);;
    overflow-y: scroll;
  } */

.modal-open .modal {
    /* padding-right: 0px !important; */
    padding: 20px !important;
}

#mo {
    display: contents;
    padding-right: 0% !important;
    padding-left: 15px !important;
}

.login {
    padding: 100px;
    padding-left: 400px;
    padding-right: 400px;
    background-color: #fff;
    min-height: 92vh;
}

.login_card {
    border: 1px solid #000;
}


@media only screen and (max-width: 600px) {
    #promob {
        display: block;
    }

    .row #title {
        display: none;
    }

    .Sidebar {
        height: 85%;
        border-radius: 20px;
        width: 50px;
        background-image: linear-gradient(to right, #54006B, #CC123D);
        position: fixed;
    }

    body {
        overflow-x: hidden;
    }

    #allpro {
        display: none;
    }

    .pad {
        padding: 10px;
        padding-top: 0px;
    }

    .login {
        padding: 10px;
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        min-height: 92vh;
    }

}

@media only screen and (max-width: 768px) {
    .row #title {
        display: none;
    }

    .Sidebar {
        height: 85%;
        border-radius: 20px;
        width: 50px;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(to right, #54006B, #CC123D);
        position: fixed;
    }

    .SidebarList .row {
        width: 100%;
        height: 60px;
        /* margin-left: -10px !important; */
        list-style-type: none;
        margin: 0%;
        display: flex;
        flex-direction: row;
        color: #fff;
        justify-content: center;
        align-items: center;
    }

    .pad {
        padding: 10px;
        padding-top: 0px;
    }

    .login {
        padding: 10px;
        margin-top: 30px;

        padding-left: 40px;
        padding-right: 40px;
        background-color: #fff;
        min-height: 92vh;
    }

    .contain {
        padding: 0px !important;
        margin-left: 0px !important;
    }
}

@media only screen and (max-width: 820px) {
    .row #title {
        display: none;
    }

    .Sidebar {
        height: 85%;
        border-radius: 20px;
        width: 50px;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(to right, #54006B, #CC123D);
        position: fixed;
    }

    .SidebarList .row {
        width: 100%;
        height: 60px;
        /* margin-left: -10px !important; */
        list-style-type: none;
        margin: 0%;
        display: flex;
        flex-direction: row;
        color: #fff;
        justify-content: center;
        align-items: center;
    }

    .pad {
        padding: 10px;
        padding-top: 0px;
    }

    .login {
        padding: 10px;
        padding-left: 40px;
        padding-right: 40px;
        background-color: #fff;
        min-height: 92vh;
        margin-top: 30px;

    }
}

@media only screen and (min-width: 992px) {
    #promob {
        display: none;
    }

}

.nav {
    float: right;
    color: aqua;
}

.css-pdct74-MuiTablePagination-selectLabel {
    margin-top: 10px;
}

.css-levciy-MuiTablePagination-displayedRows {
    margin-top: 15px;
}

.text {
    /* From https://css.glass */
    background: rgba(252, 245, 245, 0.06);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(252, 245, 245, 0.31);
}

.textlogin {
    padding: 50px;
    /* background-image: url('..//src/Components/Login/login5.png');
    backdrop-filter: blur(5px);
    background-size: "cover";
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    color: #fff; */

}

.Sidebar {
    overflow-y: scroll;

}

.modal {
    overflow-y: scroll;
}

/* Posted Job */
.box {
    background-color: #B91045;
    color: #fff;
    padding: 10px;
    font-weight: 900;
    width: 100%;
    /* display: inline-block; */
}

.posted {
    font-size: small;
}

/* End of Code Posted Job */

.ckeditor {
    padding-bottom: -100px;

}

.model {
    overflow-y: scroll;
    /* margin-top: 50px; */
}

.e-btn {
    padding: 15px;
    border-left: 1px solid black;
}

.tabs {
    overflow-x: 'scroll';
}

.modalbox {
    /* From https://css.glass */
    background: rgba(36, 2, 2, 1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    border: 1px solid rgba(36, 2, 2, 0.3);
    color: #fff;
}

.tablerow {
    color: #fff;
}

.modalbox1 {
    background-color: #fff;
    overflow-y: scroll;
    height: 700px;
    text-align: left !important;
}

.modalbutton {
    margin-top: 15px;
}

.tablecell {
    /* From https://css.glass */
    background: gainsboro;

    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    -webkit-backdrop-filter: blur(18.7px);
            backdrop-filter: blur(18.7px);
    /* -webkit-backdrop-filter: blur(18.7px); */
}

/* chnage pass */
.changepass {
    padding: 15px;
    width: 50%;
    height: auto;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* background-color: #000; */
}

/* Profilepage */

.profilepage{
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #000;
    padding: 20px;
    
}
.profil{
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
    
}
.mb-3{
    padding: 5px;
}
#basic-addon3{
    background-color: #AA0E4B;
    color: #fff;
}

.queries1{
    float: right;
    margin-top: 5px;
    margin-left: 15px;
    color: skyblue;
    cursor: pointer;
  }
  .queries2{
    float: right;
    margin-top: 5px;
    margin-left: 15px;
    color: skyblue;
    cursor: pointer;
  
  }
  .queries{
    float: right;
    margin-top: 5px;
    margin-left: 15px;
    cursor: pointer;
  
  }
.navbar {
    background-color: #fff;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
    width: "100%"
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: "100%";
    height: 80px;
    max-width: 1500px;
  }
  
  .nav-logo {
    color: #f5b921;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
    
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }
  
  .nav-links {
    color: #000;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .fa-code {
    margin-left: 1rem;
  }
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  /* .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  } */
  
  /* .nav-item:hover:after {
    width: 100%;
    background: #ffdd40;
  } */
  
  /* .nav-item .active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
  } */
  
  .nav-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
      margin-top: -30px ;
    }
  
    .nav-menu.active {
      background: #B0C4DE;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: #ffdd40;
      border: none;
    }
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #ffdd40;
      margin-top: -15px !important;
    }
  }
